import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import MapComponent from '../components/MapComponent.js';

class MapTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopUpCountries: false,
            countriesArray: ([{id: 0, label: ""}]),
        }

        this.refPopUpCountries = React.createRef();
    }

    componentDidMount(){
        window.addEventListener('click', this.onGlobalClick);

        Axios.get('/api/perm_cntry/get?is_jv=1').then(response => {
            if(checkForErrorsInRequest(response.data.msg, this.props.openAlertMessageTwoActions) == 1){ return; }
            //alert(JSON.stringify(response.data.result, null, 4));

            var countriesArray = [];
            /*var crnt_cntry = localStorage.getItem("crnt_cntry");
            if(crnt_cntry && crnt_cntry != ""){
                countriesArray.push({"id": "", "label": "Return rights to default"}); //All
            }*/

            countriesArray.push({"id": 1, "label": "Default"});

            for(let oneRow of response.data.result){
                countriesArray.push({"id": oneRow.id, "label": oneRow.label});
            }

            /*for(let oneRow of response.data.result){
                if(oneRow.id == 61){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Czech Republic
                else if(oneRow.id == 203){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Slovakia
                else if(oneRow.id == 178){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Poland
                else if(oneRow.id == 204){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Slovenia
                else if(oneRow.id == 102){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Hungary
                else if(oneRow.id == 4){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Albania
                else if(oneRow.id == 57){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Croatia
                else if(oneRow.id == 36){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Bulgaria
                else if(oneRow.id == 71){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Estonia
                else if(oneRow.id == 84){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Germany
                else if(oneRow.id == 124){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Latvia
                else if(oneRow.id == 147){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Moldova
                else if(oneRow.id == 150){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Montenegro
                else if(oneRow.id == 183){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Romania
                else if(oneRow.id == 198){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Serbia
                else if(oneRow.id == 234){countriesArray.push({"id": oneRow.id, "label": oneRow.label});} // Ukraine
            }*/
            this.setState({ countriesArray: countriesArray });

        });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onGlobalClick);
    }

    /* This method is triggered on every click on screen and is checking if some popup is opened nad user clicked out of this popup so it would close that popup in that situation */
    onGlobalClick = (e) => {
        if ( this.refPopUpCountries.current && (!this.refPopUpCountries.current.contains(e.target)) && this.state.showPopUpCountries) {
            this.setState({ showPopUpCountries: false })
        }
        //if ( this.state.showPopUpCountries ) this.refInputFilterByWho.current.focus();
    }

    changeCrntCntry = (item) => {
        //alert(item.id);
        localStorage.setItem('crnt_cntry', item.id);
        window.location.reload(true);
    }

    render() {
        return (
            <div className="mapTile">
                <div className="verticalStack">
                    <div className="horizontalStack">
                        {/*<span className="fontPoppinsSemiBold15">{"The "}{this.props.countryName}</span>*/}

                        <div className="universalPopupSelectContainer" ref={this.refPopUpCountries}>

                            <div className="universalPopupSelectVisible clickable">
                                <span className="fontPoppinsSemiBold15" onClick={() => this.setState({ showPopUpCountries: !this.state.showPopUpCountries })}>{this.props.countryName}{" >"}</span>
                            </div>

                            <div className="universalPopupSelectHidden" style={{display: this.state.showPopUpCountries ? "" : "none", marginLeft: 0, marginTop: 0}}>
                                <div className="verticalStack">
                                    {this.state.countriesArray.map(function (item, index) {
                                        return (
                                            <span className="fontPoppinsRegular13DarkGray onHoverToOrange clickable" onClick={() => {this.changeCrntCntry(item);}}>{item.label}</span>
                                        )
                                    }.bind(this))}
                                </div>
                            </div>

                        </div>

                    </div>
                    <div style={{height: "20px"}}></div>
                    <MapComponent saveCountry={this.props.saveCountry} openAlertMessageTwoActions={this.props.openAlertMessageTwoActions} />
                </div>
            </div>
        );
    }
}

export default MapTile;
